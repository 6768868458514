import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from './Home.module.css'; 
import logoImage from './logo.png'; 
import { useForm, ValidationError } from '@formspree/react';
import locationLogo from './location-icon.svg';
import whatsappLogo from './whatsapp-icon.svg';
import instaLogo from './email-icon.svg';
import fbLogo from './fb.svg';
import callLogo from './call.svg';
import WhatsAppWidget from './WhatsAppWidget';

const Contact = () => {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
      setIsOpen(!isOpen);
    };
    const handleLinkClick = () => {
      setIsOpen(false); 
    };
    const [state, handleSubmit] = useForm("mwkgjlje");
    if (state.succeeded) {
      alert("Message sent successfully");
    }
    return (
        <div>
            <Helmet>
                <title>Dream Soft Tech Pollachi | Projects, E-Sevai, Computer Services & Accessories</title>
                <meta name="description" content="Welcome to Dream Soft Tech, your tech destination for innovation and excellence in Pollachi. Explore our services including student projects, e-Sevai solutions, and tech upgrades." />
                <meta name="keywords" content="best project centre in pollachi, e-sevai services in pollachi, dream soft tech pollachi, best system services in pollachi, dream soft tech" />
                <meta property="og:title" content="Dream Soft Tech Pollachi | Projects, E-Sevai, Computer Services & Accessories" />
                <meta property="og:description" content="Dream Soft Tech Pollachi: Best project center and E-Sevai services in Pollachi. Offering multibranded laptops, desktops, CCTV, and printers. Expert services for laptops, desktops, and printers by certified technicians." />
                <meta property="og:image" content={logoImage} />
                <meta property="og:url" content="https://dreamsofttechpollachi.com" />
                <link rel="canonical" href="https://dreamsofttechpollachi.com" />
            </Helmet>
            <header className={styles.header}>
                <div className={styles.logo}>
                    <img src={logoImage} alt="Logo" />
                </div>
                <nav>
                    <div className={styles.hamburger} onClick={toggleMenu}>
                        {isOpen ? (
                            <div className={styles.closeIcon}>X</div>
                        ) : (
                            <>
                                <div className={styles.hamburgerLine}></div>
                                <div className={styles.hamburgerLine}></div>
                                <div className={styles.hamburgerLine}></div>
                            </>
                        )}
                    </div>
                    <ul className={`${styles.navLinks} ${isOpen ? styles.active : ''}`}>
                        <li>
                            <Link to="/" className={`${styles.navLink} ${location.pathname === '/' ? styles.active : ''}`} onClick={handleLinkClick}>Home</Link>
                        </li>
                        <li>
                            <Link to="/projects/" className={`${styles.navLink} ${location.pathname === '/projects/' ? styles.active : ''}`} onClick={handleLinkClick}>Final Year Projects</Link>
                        </li>
                        <li>
                            <Link to="/esevai/english" className={`${styles.navLink} ${location.pathname === '/esevai/english' ? styles.active : ''}`} onClick={handleLinkClick}>E-Sevai Services</Link>
                        </li>
                        <li>
                            <Link to="/systems/system-services" className={`${styles.navLink} ${location.pathname === '/systems/system-services' ? styles.active : ''}`} onClick={handleLinkClick}>Our Multi Branded Store</Link>
                        </li>
                        <li>
                            <Link to="/gallery" className={`${styles.navLink} ${location.pathname === '/gallery' ? styles.active : ''}`} onClick={handleLinkClick}>Gallery</Link>
                        </li>
                        <li>
              <Link to="/contact" className={`${styles.navLink} ${location.pathname === '/contact' ? styles.active : ''}`} onClick={handleLinkClick}>Contact</Link>
            </li>
                    </ul>
                </nav>
                <div className={styles.invisibleDiv}></div> {/* Invisible div */}
            </header>
            <div id="contact" className={styles.contactSection}>
        <h2 className={styles.contactHeading}>CONTACT</h2>
        <p className={styles.contactParagraph}>
        Contact Dream Soft Tech – We're Here to Help! Reach out to us for all your tech needs and collaboration inquiries. Our friendly team is ready to assist you. Let's connect and make great things happen together!
        </p>
        <div className={styles.contactWrapper}>
          <div className={styles.contactForm}>
            <form onSubmit={handleSubmit}>
              <div className={styles.formRow}>
                <div className={styles.formGroup}>
                  <label htmlFor="firstName">First Name:</label>
                  <input type="text" id="firstName" name="First Name:" style={{ height: '50px', width: '80%' }} />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="lastName">Last Name:</label>
                  <input type="text" id="lastName" name="Last Name:" style={{ height: '50px', width: '80%' }} />
                </div>
              </div>
              <div className={styles.formRow}>
                <div className={styles.formGroup}>
                  <label htmlFor="email">E-Mail:</label>
                  <input type="email" id="email" name="Email:" placeholder="E-mail Here" style={{ height: '50px', width: '100%' }} />
                </div>
              </div>
              <div className={styles.formRow}>
                <div className={styles.formGroup}>
                  <label htmlFor="mobile">Mobile Number:</label>
                  <input type="tel" id="mobile" name="Mobile Number:" placeholder="Mobile Number Here" pattern="[0-9]{10}" style={{ height: '50px', width: '100%' }} />
                </div>
              </div>
              <div className={styles.formRow}>
                <div className={styles.formGroup}>
                  <label htmlFor="message">Your Message:</label>
                  <textarea id="message" name="Enquiry Message:" placeholder="Write your thoughts here" rows="10" style={{ height: '250px', width: '100%' }}></textarea>
                  <ValidationError
                    prefix="Message"
                    field="message"
                    errors={state.errors}
                  />
                </div>
              </div>
              <button type="submit" className={styles.submitButton}>Send Message</button>
            </form>
          </div>
          <div className={styles.contactDetails}>
            <div className={styles.contactInfo}>
              <div className={styles.locationIcon}>
                <img src={locationLogo} alt="Location" />
              </div>
              <p>
                <a href="https://maps.app.goo.gl/xmLrqc6EpCQxGWBg8" rel="noreferrer">
                  111/2, AJ Complex (Ground Floor), SVV Naidu Street, Pollachi-Palakkad Main Road, Near AVM Hospital, Pollachi 642001
                </a>
              </p>
            </div>
            <div className={styles.contactInfo}>
              <div className={styles.whatsappIcon}>
                <img src={whatsappLogo} alt="WhatsApp" />
              </div>
              <p>
                <a href="https://api.whatsapp.com/send?phone=918883039728" rel="noreferrer">
                  +91 88830 39728
                </a>
              </p>
            </div>
            <div className={styles.contactInfo}>
              <div className={styles.whatsappIcon}>
                <img src={callLogo} alt="Dialer" />
              </div>
              <p>
                <a href="tel:+918883039728" rel="noreferrer">
                  +91 88830 39728
                </a><br></br>
                <a href="tel:+918015990497" rel="noreferrer">
                  +91 80159 90497
                </a>
              </p>
            </div>
            <div className={styles.contactInfo}>
              <div className={styles.instagramIcon}>
                <img src={instaLogo} alt="Instagram" />
              </div>
              <p>
                <a href="https://www.instagram.com/dreamsoftechpollachi/" rel="noreferrer">
                  dreamsoftechpollachi
                </a>
              </p>
            </div>
            <div className={styles.contactInfo}>
              <div className={styles.instagramIcon}>
                <img src={fbLogo} alt="Instagram" />
              </div>
              <p>
                <a href="https://www.facebook.com/dreamsofttechpoy?mibextid=ZbWKwL" rel="noreferrer">
                  Dream Soft Tech Pollachi
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <WhatsAppWidget/>
        </div>
    )
}
export default Contact;